<template>
  <ion-list v-if="resources.data.length > 0" class="ion-margin-top">
    <ion-list-header class="ion-margin-bottom">
      <h3>Assigned Students</h3>
    </ion-list-header>
    <ion-item v-for="item in resources.data" :key="item.id">
      <ion-label>
        <h2>
          {{ item.user.user_detail.name }}
        </h2>
        <ion-note
          >Section: {{ item.section_standard.section.name }}, Roll:
          {{ item.roll_no }}</ion-note
        >
      </ion-label>
      <ion-select
        v-if="!isMandatory"
        placeholder="Status"
        interface="popover"
        @ionChange="setSubjectStudentStatus(item.id, $event.target.value, item)"
        :value="initSubjectStudentStatus(item)"
      >
        <ion-select-option :value="1">Active</ion-select-option>
        <ion-select-option :value="0">Disabled</ion-select-option>
      </ion-select>
      <ion-note v-if="isMandatory" slot="end">Mandatory</ion-note>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No students found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonNote,
  IonSelect,
  IonSelectOption,
  alertController,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    subjectId: {
      required: true,
    },
    loadedSubject: {
      required: true,
    },
  },

  emits: [
    "refresh",
  ],

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonNote,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      updated: false,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    isMandatory() {
      const subject = this.loadedSubject;
      const mandatory = subject.is_mandatory == true ? true : false;
      return mandatory;
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async setSubjectStudentStatus(studentId, val, item) {
      if (!this.updated) {
        return;
      }

      const subjects = item.subjects;
      const data = {
        student_id: studentId,
        subject_id: parseInt(this.subjectId),
        status: val,
      };

      let student_subject_id = null;

      if (Array.isArray(subjects) && subjects.length > 0) {
        student_subject_id = subjects[0].pivot.id;
        await this.deleteSubjectStudent(student_subject_id);
      } else {
        await this.createSubjectStudent(data);
      }

      this.$emit("refresh");
    },

    async createSubjectStudent(data) {
      try {
        await this.$store.dispatch("subjectStudent/createResource", data);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    async deleteSubjectStudent(id) {
      try {
        await this.$store.dispatch("subjectStudent/deleteResource", {
          resourceId: id,
          subjectId: this.subjectId,
        });
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    initSubjectStudentStatus(item) {
      const subjects = item.subjects;
      let subjectStudentStatus = 0;

      if (Array.isArray(subjects) && subjects.length > 0) {
        subjectStudentStatus = subjects[0].subject_id == this.subjectId ? 1 : 0;
      }
      return subjectStudentStatus;
    },
  },

  beforeUpdate() {
    this.updated = false;
  },

  updated() {
    this.updated = true;
  },
};
</script>